import { DateTime } from 'luxon'
import { nbMsInDay, nbMsInHour, nbMsInMinute, nbMsInMonth, nbMsInSecond, nbMsInWeek, nbMsInYear } from './constants'
import { lang } from './translate'

/**
 * @function toIso
 * @description Convert a date to extended ISO 8601 format (in the local time)
 * @param date The date to convert
 * @returns The converted date
 */
export function toIso(date?: Date) {
  return date ? DateTime.fromJSDate(date).toISO() : undefined
}

/**
 * Format a date to ISO without time
 * @param date input date
 * @returns string like : "2019-12-31"
 */
export function dateIso10(date: Readonly<Date> = new Date()) {
  return String(date.toISOString().split('T')[0])
}

/**
 * Format a date to a specific format
 * @param date input date
 * @param format the format to use like : "yyyy-MM-dd" or "dd/MM/yyyy HH:mm:ss"
 * @param locale the locale to use, default is en-US
 * @returns a string like : "2018-09-03"
 */
export function formatDate(date: Readonly<Date>, format: string, locale = lang) {
  /* eslint-disable complexity, max-statements */
  // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: cant be simplified ^^
  return format.replace(/y{4}|yy|M{4}|MM|dd|d|e{4}|e{3}|HH|mm|ss|\s/gu, match => {
    if (match === 'yyyy') return date.toLocaleDateString(locale, { year: 'numeric' })
    if (match === 'yy') return date.toLocaleDateString(locale, { year: '2-digit' })
    if (match === 'MMMM') return date.toLocaleDateString(locale, { month: 'long' })
    if (match === 'MM') return date.toLocaleDateString(locale, { month: '2-digit' })
    if (match === 'dd') return date.toLocaleDateString(locale, { day: '2-digit' })
    if (match === 'd') return date.toLocaleDateString(locale, { day: 'numeric' })
    if (match === 'eeee') return date.toLocaleDateString(locale, { weekday: 'long' })
    if (match === 'eee') return date.toLocaleDateString(locale, { weekday: 'short' })
    if (match === 'HH') return date.getHours().toString().padStart(match.length, '0')
    if (match === 'mm') return date.getMinutes().toString().padStart(match.length, '0')
    if (match === 'ss') return date.getSeconds().toString().padStart(match.length, '0')
    return match
  })
  /* eslint-enable complexity, max-statements */
}

/**
 * Make a date readable for us, poor humans
 * @param input a date or a number of milliseconds
 * @param isLong true to return a short version like "3d" instead of "3 days"
 * @returns "1 minute", "4 months" or "1min", "4mon"
 * @example readableTime(3 * nbMsInDay) // "3 days"
 * @example readableTime(3 * nbMsInDay, false) // "3d"
 */
export function readableTime(input: number | Readonly<Date>, isLong = true) {
  const ms = typeof input === 'number' ? input : Date.now() - input.getTime()
  // eslint-disable-next-line jsdoc/require-jsdoc
  const format = (value: number, long: string, short: string) => `${Math.floor(value)}${isLong ? ` ${long + (Math.floor(value) > 1 ? 's' : '')}` : short}`
  if (ms < nbMsInSecond) return format(ms, 'millisecond', 'ms')
  if (ms < nbMsInMinute) return format(ms / nbMsInSecond, 'second', 's')
  if (ms < nbMsInHour) return format(ms / nbMsInMinute, 'minute', 'min')
  if (ms < nbMsInDay) return format(ms / nbMsInHour, 'hour', 'h')
  if (ms < nbMsInMonth) return format(ms / nbMsInDay, 'day', 'd')
  if (ms < nbMsInYear) return format(ms / nbMsInMonth, 'month', 'mon')
  return format(ms / nbMsInYear, 'year', 'y')
}

const timeAgoTuples = [
  [nbMsInMinute, nbMsInSecond, 'second'],
  [nbMsInHour, nbMsInMinute, 'minute'],
  [nbMsInDay, nbMsInHour, 'hour'],
  [nbMsInWeek, nbMsInDay, 'day'],
  [nbMsInMonth, nbMsInWeek, 'week'],
  [nbMsInYear, nbMsInMonth, 'month'],
] as const

/**
 * Make a date readable for us, poor humans
 * Kudos to https://coolaj86.com/articles/time-ago-in-under-50-lines-of-javascript/
 * @param input a date or a number of milliseconds
 * @param language the language to use, default is "en"
 * @returns "a minute ago", "4 days ago"
 */
export function readableTimeAgo(input: number | Readonly<Date>, language = 'en') {
  const rtf = new Intl.RelativeTimeFormat(language, { numeric: 'auto' })
  const ms = typeof input === 'number' ? input : Date.now() - input.getTime()
  for (const [threshold, divisor, unit] of timeAgoTuples) if (ms < threshold) return rtf.format(-Math.floor(ms / divisor), unit)
  return rtf.format(-Math.floor(ms / nbMsInYear), 'year')
}

/**
 * Return a relative Date in the past
 * @param nbDays the number of days to subtract from today
 * @returns a Date
 * @example daysAgo(1) // yesterday
 */
export function daysAgo(nbDays = 0) {
  const date = new Date()
  date.setDate(date.getDate() - nbDays)
  return date
}

/**
 * Return a relative Date in the future
 * @param nbDays the number of days to add to today
 * @returns a Date
 * @example daysFromNow(1) // tomorrow
 */
export function daysFromNow(nbDays = 0) {
  return daysAgo(-nbDays)
}

/**
 * Return a relative date formatted to ISO 10
 * @param nbDays the number of days to subtract from today
 * @returns a string like : "2019-12-31"
 */
export function daysAgoIso10(nbDays = 0) {
  return dateIso10(daysAgo(nbDays))
}
